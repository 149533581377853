import firebase,{storage} from "../firebase"
import { getCurrentUser} from "../Common/LocalStorage";
import Resizer from "react-image-file-resizer";
import { DefaultImageMetrics,Locations, UserPermission } from "./Consts";
import { getStorage, ref, uploadString } from "firebase/storage"
import { get_user } from "./DBUtils";
import CryptoJS from 'crypto-js';

// recieves base64 encoding of an image, returns a string of encrypted bytes of the provided image.
export const encryptImage = async (image) => {
  try {
    var key ='rJqe4rPi9DaOklED' //key used in Python for decrypting (has to be 16 chars)
    key = CryptoJS.enc.Utf8.parse(key);
    var iv = CryptoJS.enc.Utf8.parse('X1iMwuQ7tZ8Nx1uH') // also used in Python (has to be 16 chars)
    var encryptedString = CryptoJS.AES.encrypt(image, key, { iv: iv, mode: CryptoJS.mode.CBC});
    encryptedString =encryptedString.toString();

    return encryptedString;
    
  } catch (error) {
    console.error('Error encrypting image:', error);
    throw error;
  }
};

// recieves encrypted bytes and returns the decrypted image in base64 format. 
export const decryptImage = (imgData) => {
  try {
      var iv = CryptoJS.enc.Utf8.parse('X1iMwuQ7tZ8Nx1uH'); // same key, iv as encrypt
      var key ='rJqe4rPi9DaOklED'
      key = CryptoJS.enc.Utf8.parse(key);

      var decrypted =  CryptoJS.AES.decrypt(imgData, key, { iv: iv, mode: CryptoJS.mode.CBC});
      decrypted = decrypted.toString(CryptoJS.enc.Utf8);

      return decrypted;

  } catch (error) {
    console.error('Error encrypting image:', error);
  }
};

// fetching the encrypted data and returning the decrypted image in base64 format.
export const fetchAndDecrypt = (imgURL,imgName) => {
  // for backward compatibility
  if(!imgName.includes("___enc___"))
    return imgURL

  return new Promise((resolve, reject) => {
    fetch(imgURL)
      .then((response) => response.blob())
      .then(async (encryptedBlob) => {
        const reader = new FileReader();

        reader.onload = () => {
          const encryptedBinaryData = reader.result;
          const decrypted = decryptImage(encryptedBinaryData)

          resolve(decrypted);
        };

        reader.onerror = () => {
          reject(new Error('Error reading the encrypted blob.'));
        };

        reader.readAsBinaryString(encryptedBlob);
      })
      .catch((error) => {
        console.error('Error fetching or decrypting:', error);
        reject(error);
      });
  });
};

export const getImageDimensionsByFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      };
      img.onerror = reject;
      img.src = e.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// convert a base64 data URI to a File object
function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length, 
    u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

  const uploadPDFTask = async (folder, fileNameToStore, imageAsFile, resolve, reject) => {
    const uploadTask = storage.ref(`/`+folder+`/${fileNameToStore}`).put(imageAsFile)
    //const uploadTask = storage.ref('/'+folder+'/'+imageAsFile.name)
    uploadTask.on('state_changed',
    (   snapShot) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot)
        },(err) => {
        console.log('error', err)
        reject()
        },
        //complete function
        () => {
        storage.ref(folder).child(fileNameToStore).getDownloadURL().then(function(downloadURL) {
            console.log("fireBaseUrl="+downloadURL)
            resolve(downloadURL)
        })
        }
    )
  }

const updateImageTask = async (imageAsFile, fileNameToStore, folder, resolve, reject, imageHeight, should_encrypt=false) => {
    const dimension = await getImageDimensionsByFile(imageAsFile);
    console.log("dimension: "+JSON.stringify(dimension));
    Resizer.imageFileResizer(
      imageAsFile,
      Math.round(dimension.naturalWidth/dimension.naturalHeight*imageHeight * 100) / 100, // width
      imageHeight, // height
      "JPEG",
      100, //quality
      0,
      async (uri) => {
        try {
          let uploadTask;
          if(!should_encrypt || folder.startsWith('student-images'))
          {
            const resizedImageAsFile = dataURLtoFile(uri,fileNameToStore);
            uploadTask = storage.ref(`/${folder}/${fileNameToStore}`).put(resizedImageAsFile);
          }
          else
          {
            const encryptedData = await encryptImage(uri); // Wait for encryption to complete
            const blob = new Blob([encryptedData], { type: 'application/octet-stream' });
            uploadTask = storage.ref(`/${folder}/${fileNameToStore}`).put(blob);
          }
          uploadTask.on('state_changed',
            (snapShot) => {
              console.log("upload succeeded")
            },
            (err) => {
              console.log('error', err)
              reject()
            },
            () => {
              storage.ref(folder).child(fileNameToStore).getDownloadURL().then(function (downloadURL) {
                resolve(downloadURL)
              })
            }
          )
        } catch (error) {
          console.error('Error uploading encrypted image:', error);
          reject(error);
        }
      }, "base64"
    );
  }

  // A function to create unique names for external images
  export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  export const UploadWebImageToFirebase = async (image, image_name,folder) => {
    
    // Download the image from Unsplash
    try {
      const response = await fetch(image);
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
  
      const blob = await response.blob();
      const file = new File([blob], image_name, { type: 'image/jpeg' });
  
      // Upload the file to Firebase storage
      const currentUser = getCurrentUser();
      const storageRef = storage.ref();
      const imageRef = storageRef.child(`/${folder}/${currentUser.uid}/${image_name}`);
  
      const uploadTask = imageRef.put(file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Upload failed:', error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
          });
        }
      );
    } catch (error) {
      console.error('Error downloading or uploading image:', error);
    }
  };  

const uploadTaskPromise = async (imageAsFile, folder='images', custom_file_name=null, imageHeight, should_encrypt=false) => {
    return new Promise( async (resolve, reject) => {
        let fileNameToStore = custom_file_name || imageAsFile.name
        if (!fileNameToStore || fileNameToStore=='')
          throw 'no filename to store' 

      if(imageAsFile.type=="application/pdf")
      {
        await uploadPDFTask(folder, fileNameToStore, imageAsFile, resolve, reject)
      }
      else //imageAsFile.type=="img"
      {
        await updateImageTask(imageAsFile, fileNameToStore, folder, resolve, reject, imageHeight, should_encrypt)
      }
    })
}

export const uploadThemeImageToCloudStorage = (async (e, imageAsFile, parentOfImgPickerCallback, setImageAsFile) => {
    let uploadedToURL = false
    try{
      e.preventDefault()
      if(imageAsFile === '' ) {
        console.error('[not an image, the image file is a:'+typeof(imageAsFile))
      }
      else{
        await uploadToFileStorage(imageAsFile,'images/'+getCurrentUser().uid, null, DefaultImageMetrics.THEME_IMAGE_HEIGHT).then((responseURL) => {
          if (responseURL){
            //send uploaded image's url to parent
            parentOfImgPickerCallback(responseURL, imageAsFile.name)
            //reset selected image name
            setImageAsFile('')
            uploadedToURL = responseURL
          }
        },
        (error) => {console.log("failed to upload image to file storage", error)})
      }
    }
    catch(error){
      console.error("failed to upload theme image",error)
    }

    return uploadedToURL
  })

//on form sumbit - upload image to firebase storage
export const uploadToFileStorage = (file, folder, customFileName) => {
  return new Promise((resolve, reject) => {
    if (file && !file.name)
      file.name = `${crypto.randomUUID()}_${Date.now()}`;

    const storageRef = storage.ref(`${folder}/${customFileName || file.name}`);
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      "state_changed",
      snapshot => {
        // Progress monitoring
        console.log(snapshot);
      },
      error => {
        console.error("Upload error: ", error); // Detailed error logging
        reject(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          console.log("File available at: ", downloadURL);
          resolve(downloadURL);
        }).catch(error => {
          console.error("Get download URL error: ", error); // Detailed error logging
          reject(error);
        });
      }
    );
  });
};


export const uploadSoundToFirebase = async (sound) => {
  const customFileName = `${generateRandomString(16)}.webm`;
  const fileRef = storage.ref(`${Locations.SOUND_FOLDER}/${customFileName}`);
  
  try {
    await fileRef.put(sound, { contentType: sound.type });
    const soundUrl = await fileRef.getDownloadURL();
    return soundUrl;
  } catch (error) {
    console.error("Failed to upload sound to file storage", error);
    throw error;
  }
};


export const removeFromFileStorage = async (fileName, folder='images') => {
  let isDeleted = false 
  try
  {
      console.log('start of file deletion')
      // async magic goes here...
      if(fileName === '' ) {
      console.error('[not an file, the file is a ${typeof(imageAsFile)}')
      }
      
      await storage.ref(folder).child(fileName).delete().then(function(response) {
        console.log("delete() fileName:"+fileName)
        isDeleted=true;
    })
      console.log("remove image:"+isDeleted)
  }
  catch(error)
  {
      console.error("failed to remove file:"+fileName+" from cloud-storage. fileName:"+fileName,error)
  }
  return isDeleted
}

export const isPermittedUser = (line) => {
  let currentUser = getCurrentUser();
  if(currentUser && currentUser.email && currentUser.email != '' && currentUser.email.includes(line)){
    return true;
  }
  return false;
}


export const isPaidConsumerOnFile = async() =>{
   let isPermitted=false
  try
  {
    const fileUrl=process.env.REACT_APP_PERMISSIONS_FILE;
      // async magic goes here...
      let response = await fetch (fileUrl);
      const txt = await response.text().then(( str ) => {
          let lines = str.split('\n');
          for (let line = 0; line < lines.length; line++) {
            if(isPermittedUser(lines[line])){
              isPermitted= true;
            }
            
          }   
        })
      .catch((error) => {
        console.error("failed to calc isPaidConsumerOnFile",error)
      });
  }
  catch(error)
  {
      console.error("failed to calc isPaidConsumerOnFile",error)
  }
  return isPermitted;
}

export const isPaidConsumerOnDB = async(user_email) =>{
  let isPermitted=false
  try{
     await get_user(user_email).then((response)=>{
      const paymentDetails=response.payment
      if(paymentDetails && paymentDetails.is_active_payer)
          isPermitted= true;
     }).catch((error) => {
        console.error("failed to calc isPaidConsumerOnFile",error)
      });
  }catch(err){
    console.error("failed to calc isPaidConsumerOnDB",err)
  }

  return isPermitted;
}

export const isPaidConsumerUser = async () => {
      let isPermitted = false;
      let currentUser = getCurrentUser();
    try {
        isPermitted = await isPaidConsumerOnDB(currentUser.email);
        if (!isPermitted) {
            isPermitted = await isPaidConsumerOnFile();
        }
    } catch (error) {
        console.error("Failed to calculate isPaidConsumerUser", error);
    }
    return isPermitted;
  }

const handleStorageBytesUpload = (bytes, fileName, parentCallback, folderName,user_email='') => {
  
  console.log('starting  image upload')
  console.log('start of image upload')
  // async magic goes here...
  if(fileName === '' ) {
    console.error('[not an image, the image file is a ${typeof(imageAsFile)}')
  }
  const storage = getStorage();
  const storageRef = ref(storage, ('/'+folderName+user_email+fileName))
  
  //if image - upload as data_url, if text - upload as is
  const promise = folderName == Locations.IMAGE_FOLDER ? 
        uploadString(storageRef, bytes, 'data_url'):
        uploadString(storageRef, bytes)
  promise.then((snapshot) => {
    console.log('Uploaded to '+storageRef);
    //parent to remove visual upload indication
    parentCallback(storageRef.bucket+'/'+storageRef.fullPath)
  },
  (err) => {
    //catches the errors
    console.log(err)
    return null;
  });
}

export const updateExistingImageUpload = (imageBytes, imageName, parentCallback, user_email) => {
    return handleStorageBytesUpload(imageBytes, imageName, parentCallback, Locations.IMAGE_FOLDER, user_email+'/')
  }