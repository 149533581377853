import { doc } from "firebase/firestore";
import {getFirebaseUser} from "../firebase"
import { getIdToken } from "firebase/auth"

export const performExport = async ({ story_id, user_email, setShowToast, book_name }) => 
{
    const exportURL = process.env.REACT_APP_EXPORT_PDF_URL;
    setShowToast(true);
    const export_call_body = JSON.stringify({ story_id: story_id, user_email: user_email })
    console.log("export_call_body: "+export_call_body)

    try {
      // Make a POST request to the server to export the story to PDF
      let currentUser = getFirebaseUser();
      let token_id = await getIdToken(currentUser);

      // Make the POST request to the server
      const response = await fetch(exportURL, {
        method: "POST",
        body: export_call_body,
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token_id}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to export PDF: ${response.status} ${response.statusText}`
        );
      }

      // Extract the URL from the response
      const url = await response.text();
      console.log("Generated PDF URL:", url);

      // Return the URL to the caller
      return url;
    } catch (error) {
      console.error("Error in performExport:", error);
      setShowToast(false);
      throw error; // Re-throw the error for the caller to handle
    }
    
    /*
    fetch(exportURL, {
      method: 'POST',
      body: export_call_body,
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token_id}`
    },
    })
    .then(res => res.text())
    .then(url => {
        console.log("Generated PDF URL:", url);
        return url;
        */

        /*
        const downloadLink = document.createElement('a');
        downloadLink.
        downloadLink.href = url;
        const downloadText = document.createElement('p');
        downloadText.textContent = 'Download PDF';
        downloadLink.appendChild(downloadText);
        document.body.appendChild(downloadLink);
        */
      
        //Create a close button which closes the toast
        /*
        const closeToastButton = document.createElement('button');
        closeToastButton.textContent = 'X';
        closeToastButton.addEventListener('click', () => {
          setShowToast(false);
        });
        document.body.appendChild(closeToastButton);
        */
    //})
    /*
      .then((response) => response.blob())
      .then((blob) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = book_name+'.pdf';

        downloadLink.click();
        //setShowToast(false);
      })
      .catch((error) => {
        console.error('Perform-pdf-export error:', error);
        setShowToast(false);
        throw error;
      });
      */
  };