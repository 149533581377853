import i18n from "../../i18n"; // Adjust path to your i18n setup
import { franc } from "franc"; // Language detection library

let disableTranslationHandler = false;


export const monitorTextForTranslation = () => {
  const elementToMonitor = document.querySelector("#monitorWord");

  if (!elementToMonitor) {
    console.error("No element with ID 'monitorWord' found!");
    return;
  }

  // Fallback mapping for single words or phrases
  const fallbackMapping = {
    "בַּיִת": "he", // Hebrew for "Home"
    "بيت": "ar",   // Arabic for "Home"
    "होम": "hi",   // Hindi for "Home"
    "Heim": "ge",  // German for "Home"
    "Home": "en",  // English for "Home"
  };

  // Initial text content
  let originalText = elementToMonitor.innerText;

  // Use MutationObserver to detect changes in text content
  const observer = new MutationObserver(() => {
    const currentText = elementToMonitor.innerText.trim();
    if (currentText !== originalText) {
      console.log(`Text changed from "${originalText}" to "${currentText}"`);

      // Try detecting the language using `franc`
      let detectedLang = franc(currentText);
      console.log(`Detected Lang (franc): ${detectedLang}`);

      // Use fallback if `franc` fails or returns "und"
      if (detectedLang === "und" || !detectedLang) {
        detectedLang = fallbackMapping[currentText] || "en"; // Default to English
        console.log(`Detected Lang (fallback): ${detectedLang}`);
      }

      // Map detectedLang to i18n-compatible languages
      const languageMapping = {
        heb: "he", // Hebrew
        arb: "ar", // Arabic
        mai: "hi", // Hindi
        hin: "hi", // Hindi
        mai: "hi", // Hindi
        deu: "ge", // German
        eng: "en", // English
      };

      const targetLang = languageMapping[detectedLang] || detectedLang; // Use fallback or franc result
      console.log(`Target Lang: ${targetLang}`);

      // Dynamically switch the language in i18n
      if (targetLang && i18n.language !== targetLang) {
        i18n.changeLanguage(targetLang); // Change the app's language
        console.log(`App language switched to: ${targetLang.toUpperCase()}`);
      }
    }
  });

  observer.observe(elementToMonitor, { characterData: true, subtree: true, childList: true });

  // Cleanup function
  return () => observer.disconnect();
};