import React, {useCallback, useEffect, useState} from 'react'
import './App.css';
import { I18nextProvider, useTranslation } from "react-i18next"
import i18n from "./i18n";
//import { useTranslation } from "react-i18next";
import NavbarBootsrap from './Common/NavbarBootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { EditBookScreen } from './BookEdit/EditBookScreen'
import { BrowserRouter,
          Routes,
          Route, } from "react-router-dom"
import { SearchScreen } from './BookSearch/SearchScreen'
import { YourStoriesScreen } from './BookSearch/YourStoriesScreen'
import { LoginScreen } from './UserMgmt/LoginScreen'
import { LoginModalDialog } from './LoginModalDialog';
import {LocalStorageKeys} from './BookEdit/Consts'
import {useStateWithLocalStorage} from './Common/LocalStorage'
import { ContactUsForm } from './Common/ContactUsForm';
import AboutPage from './AboutPage/AboutPage';
import EditStudentPage from './UserMgmt/EditStudentPage';
import StudentsPage from './UserMgmt/StudentsPage';
import {PrivacyPolicy} from './Common/PrivacyPolicy'
import { TermsOfUse } from './Common/TermsOfUse';
import { update_user_properties, get_group_name, get_user } from './BookEdit/DBUtils';
import { OrganizationStudentsPage } from './Organization/OrganizationStudentsPage';
import { OrganizationStoriesPage } from './Organization/OrganizationStoriesPage';
import { OrganizationGroupUser } from './Organization/OrganizationGroupUsers';
import {GenerativeStoryScreen} from './GenerativeStory/GenerativeStoryScreen'
import { GefenProgramPage } from './Common/GefenProgramPage';
import { AccessibilityDocument } from './Common/AccessibilityDocument';
import PaymentFailedPage from './Common/PaymentFailedPage';
import WelcomePostPurchasePage from './payments/WelcomePostPurchasePage';
import { monitorTextForTranslation } from './Common/Utils/GoogleTranslateHandler';


export const App = () => {
    const [postFirebaseLogin,setPostFirebaseLogin] = useState(false)
    const [user, setUser] = useStateWithLocalStorage(LocalStorageKeys.userKey);
    const [userMetadata, setUserMetadata] = useStateWithLocalStorage(LocalStorageKeys.USER_GROUP_NAME)
    const [orgId, setOrgId] = useState(null)
    const [groupName, setGroupName] = useStateWithLocalStorage()
    const getDirection = () => (i18n.language=== "he" ? "rtl" : "ltr");
    
    
    const postUserLogin = async (authResult, orgId=null, groupId=null) => {
      console.log("post user login")
      setPostFirebaseLogin(true)
      setUser(JSON.stringify(authResult.user))
      let user_email = authResult.user && authResult.user.email ? authResult.user.email : null
      let props_to_update = new Array('user_consent')
      let values_to_update = null
      if (groupId!=null && orgId!=null){
        props_to_update.push('organizations');
        values_to_update = {'group_id':groupId, 'org_id':orgId}
      } 
      await update_user_properties(user_email, props_to_update, values_to_update ? values_to_update : null).then(async(get_user_result)=>{
        let json_user_result = JSON.stringify(get_user_result);
        console.log("updated user properties: ", get_user_result);
        setUserMetadata(json_user_result)
        if(authResult.additionalUserInfo.isNewUser){
          localStorage.setItem(LocalStorageKeys.new_user,JSON.stringify(authResult))
        }

        await setUserGroupName(get_user_result).then(response => {
          //setGroupName(response)
          console.log("got group_name:"+response)
        })
      })
    }

    const setUserGroupName = async (get_user_result) => {
      let result = ""
      if (get_user_result && get_user_result.organizations && 
        get_user_result.organizations.id && get_user_result.organizations.groups &&
        get_user_result.organizations.groups.length > 0 && get_user_result.organizations.groups[0].group_id)
        {
          let orgid = get_user_result.organizations.id
          let groupid = get_user_result.organizations.groups[0].group_id
          await get_group_name(groupid, orgid, i18n.language).then((response) =>{
            result = response
            setGroupName(response)
          })
        }
      return result
    }
    
    //pop login dialog in case trying to perform DB actions with no user
    const [modalLoginShow, setModalLoginShow] = useState(false);
    
    const postContextLogin = (authResult) => {
        postUserLogin(authResult)
        setModalLoginShow(false)
        window.location.reload()
    }

    const handleLoginDialogHide = () => {
        setModalLoginShow(false);
        window.location.replace("http://"+window.location.host)
    }
 
    const noUserCallback = () => {
      setModalLoginShow(true)
    }

useEffect(() => {
    const script = document.createElement('script');
    script.src = '/acctoolbar.min.js'; // <--- Here
    script.async = true;
    const userlang =( i18n.language == 'he') ? 'he-IL' : 'en'
    script.onload = () => {
      script.micAccessTool = new MicAccessTool({
        link: '/accessibility-document',
        contact: '/contact',
        buttonPosition: 'right',
        forceLang: userlang
      });
    };
    document.head.appendChild(script);
  }, [i18n.language]);


    const onLogout = useCallback(() =>
    {
        setUser("")
        setUserMetadata("")
        // Redirect to the ‘/search’ route after logout
        window.location.href = '/search';
    },[user, setUser, userMetadata, setUserMetadata])

    const postAiStoryGeneration = (generatedBookId) => {
      let uid = JSON.parse(user).uid
      window.location.href = '/edit/'+uid+'/'+generatedBookId;
    }
    useEffect(() => {
      const cleanupObserver = monitorTextForTranslation();
  
      return () => {
        if (cleanupObserver) cleanupObserver();
      };
    }, []);

    return (
    <I18nextProvider i18n={i18n}>

      <BrowserRouter>
        <div><NavbarBootsrap
              user = {user}
              onLogout = {onLogout}
              userMetaString = {userMetadata}
        />
        </div>
        {/* A <Switch> looks through its children <Route>s and
        renders the first one that matches the current URL. */}
        <Routes>
          <Route path='/yourstories' element={<YourStoriesScreen/>} />
          <Route path='/yourstories/:deleted_book_id' element={<YourStoriesScreen/>} />
          <Route path='/search' element={<SearchScreen 
                                        userMetaString={userMetadata}
                                        groupName={groupName}
                                        userData = {user}
                                        noUserCallback={noUserCallback}/>} />
          <Route path="/edit" element={<EditBookScreen
                                        user = {user}
                                        noUserCallback = {noUserCallback}
                                        userMetaString={userMetadata}
                                        />}/>
          <Route path="/login" element={<LoginScreen 
                                        onLogin={postUserLogin}
                                        shouldRedirect
                                        postFirebaseLogin={postFirebaseLogin}
                                        />}/>
          <Route path="/login/:orgid/:groupid" element={<LoginScreen 
                                        onLogin={postUserLogin}
                                        shouldRedirect
                                        postFirebaseLogin={postFirebaseLogin}
                                        />}/>
          <Route path="/" element={user ? <SearchScreen/> : <AboutPage/>}/>
          <Route path="/edit/:groupid" element={ <EditBookScreen 
                                        user = {user}
                                        noUserCallback = {noUserCallback}  
                                        userMetaString={userMetadata}                     
          />}/>
          <Route path="/edit/:userid/:bookid/" element={ <EditBookScreen 
                                        user = {user}
                                        noUserCallback = {noUserCallback}
                                        userMetaString={userMetadata}                       
          />}/>
          <Route path="/edit/:userid/:bookid/:groupid" element={ <EditBookScreen 
                                        user = {user}
                                        noUserCallback = {noUserCallback}
                                        userMetaString={userMetadata}                       
          />}/>
          <Route path="/contact/:fill" element={<ContactUsForm/>}/>
          <Route path="/contact" element={<ContactUsForm/>}/> 
          <Route path="/contact/:campaignid" element={<ContactUsForm/>}/>         
          <Route path="/editstudent" element={<EditStudentPage
                                              user={user}
                                              noUserCallback = {noUserCallback}  
                                            />}/>
          <Route path="/editstudent/:studentid" element={<EditStudentPage
                                              user={user}
                                              noUserCallback = {noUserCallback}  
                                            />}/>
          <Route path="/editstudent/:studentid/:groupid" element={<EditStudentPage
                                            user={user}
                                            noUserCallback = {noUserCallback}  
                                          />}/>
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/students" element={<StudentsPage noUserCallback = {noUserCallback}  />}/>
          <Route path="/organizationstudents" element={<OrganizationStudentsPage 
                                                        userMetaString={userMetadata} 
                                                        groupName={groupName}  />}/>
          <Route path="/organizationstories" element={<OrganizationStoriesPage 
                                                          userMetaString={userMetadata}
                                                          groupName={groupName}/>}/>
          <Route path="/organizationusers/:org_id" element={<OrganizationGroupUser 
                                                                userMetaString={userMetadata} 
                                                                groupName={groupName} />}/>
          <Route path="/students" element={<StudentsPage noUserCallback = {noUserCallback}  />}/>
          <Route path="/ai-story" element={<GenerativeStoryScreen 
                                              userMetaString={userMetadata}
                                              userString={user}
                                              postAiStoryGeneration={postAiStoryGeneration}
                                              noUserCallback = {noUserCallback}
                                              />}/>
          <Route path="/ai-story/:groupid" element={<GenerativeStoryScreen 
                                    userMetaString={userMetadata}
                                    userString={user}
                                    postAiStoryGeneration={postAiStoryGeneration}
                                    />}/>
          <Route path="/privacy" element={<PrivacyPolicy/>}/>
          <Route path="/terms" element={<TermsOfUse/>}/>
          <Route path="/moe-gefen" element={<GefenProgramPage/>}/>
          <Route path="/accessibility-document" element={<AccessibilityDocument/>}/>
          <Route path="/paymentfailed" element={<PaymentFailedPage getDirection={getDirection}/>}/>
          <Route path="/welcometoozzy" element={<WelcomePostPurchasePage user = {user}/>}/>
        </Routes>
      </BrowserRouter>
      <LoginModalDialog 
        shouldShow = {modalLoginShow}
        onLogin = {postContextLogin}
        handleLoginDialogHide = {handleLoginDialogHide}
        postFirebaseLogin={postFirebaseLogin}
        />

  </I18nextProvider>
  );
}

export default App;
