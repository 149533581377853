import React, {useState, useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import './editbook.css'
import { t } from 'i18next';
import {IoIosPaperPlane,IoIosCopy} from 'react-icons/io'
import {FaFilePdf} from 'react-icons/fa'
import Button from "react-bootstrap/Button";
import { GiSewingMachine } from "react-icons/gi";
import {performExport} from './ExportPdfUtils';
import { PdfDownloadLoader } from './PdfDownloadLoader';

export const EditBookGlobalActions = ({bookMetadata, setToastVisibleCallback, setToastLoadText, 
                                      book_id, setBookNameCallback, storeCurrentBookPageCallback,
                                      setShowPublishDialogCallback, nextPage, prevPage,viewOnlyMode,setModalPersonalContentShow,isPermittedUser,setUpgradeModalShow,pdfs_store_completed,clonedFromFreeStory,isCreatorStory,user_email }) => {

    const [bookNameDisplay,setBookNameDisplay] = useState("")//useState(bookMetadata.book_name || t("default_book_name"))
    const [showPDFLoadToast, setShowPDFLoadToast] = useState(false);
    const initialToastBody = t('export_book_pdf_loader') + ' ' + (bookNameDisplay!=undefined ? bookNameDisplay : null || bookMetadata.book_name);
    const [pdfUploadToastBody, setPdfuploadToastBody] = useState(initialToastBody);
    const [pdfUploadLink, setPdfUploadLink] = useState(null);

    useEffect(() => {
      console.log("got book_name:"+bookMetadata.book_name)
      if (bookMetadata.book_name) setBookNameDisplay(bookMetadata.book_name || t("default_book_name"))
    },[bookMetadata])

      const onPublishClick = () => {
        console.log("publish button clicked")
        storeCurrentBookPageCallback(nextPage, prevPage).then(
          setShowPublishDialogCallback(true))
    }

  const handleExportPDF = async () => {
    console.log("export button clicked");
    let book_name = bookNameDisplay || bookMetadata.book_name;

    try {

       // Ensure storeCurrentBookPageCallback completes first
       const res = await storeCurrentBookPageCallback();
       console.log("Book ID from storeCurrentBookPageCallback:", res.book_id);

       // Call performExport and await its result
       const pdfUrl = await performExport({
           story_id: res.book_id,
           user_email: bookMetadata.creator_email,
           setShowToast: setShowPDFLoadToast,
           book_name,
       });

       // Check if pdfUrl is valid
       if (pdfUrl) {
           console.log("Received PDF URL:", pdfUrl);

           // Update the toast message with the PDF URL

          //How I know if its hebrew or not so I can send the correct massage
           //setPdfuploadToastBoday(isHebrew ? `קובץ PDF: ` : `Link to PDF: `);
           setPdfuploadToastBody(t(bookNameDisplay));
           setPdfUploadLink(pdfUrl);
       } else {
           console.error("No URL received from performExport");
           setPdfuploadToastBody("Failed to generate PDF link. Please try again.");
       }


       /*

      await storeCurrentBookPageCallback().then((res) => {
        // await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds
        performExport({
          story_id: res.book_id,
          user_email: bookMetadata.creator_email,
          setShowToast,
          book_name,
        }).then((response) => {
          console.log(response);
          console.log("Updating toast body");
          setPdfuploadToastBoday("link to pdf:" + response);
        });
      });
      */
    } catch (error) {
      console.error("Error exporting PDF:", error);
      setPdfuploadToastBody("Unexpected error occurred. Please try again.");
    }
  };
    const onTailorClick = () => {
      console.log("tailor button clicked");
      firebase.analytics().logEvent('tailor_btn_click');
      (bookMetadata.creator_email==user_email) ? (
        storeCurrentBookPageCallback().then(
        setModalPersonalContentShow(true)
      )) : (
        setModalPersonalContentShow(true)
      )   
    }

    const onBookNameChange = (e) =>{
      setBookNameDisplay(e.target.value)
      setBookNameCallback(e.target.value)
    }
    const showUpgradeModal = ()=>{
      console.log("disabled tailor button clicked")
      setUpgradeModalShow(true)
    }

      return (
        <div className="bookDetailsRow">
        <input
          value={bookNameDisplay}
          className="bookName"
          placeholder={t("default_book_name")}
          onChange={onBookNameChange}
        ></input>
        <span className="book-buttons">

        {viewOnlyMode ? 
         null:(
          <Button className="btn" onClick={handleExportPDF} disabled={showPDFLoadToast}>
          <span className="book-button-text">{t('export_book_pdf_btn')}</span>
          <span>
            <FaFilePdf className="book-btn-icon" />
          </span>
        </Button>      
          )}
          {viewOnlyMode ? 
           (<Button className="btn" onClick={onTailorClick}>
            <span className="book-button-text">{t("tailor_book_btn")}</span>
            <span>
              <GiSewingMachine className="book-btn-icon" />
            </span>
          </Button>):
          ((isPermittedUser || !clonedFromFreeStory || !isCreatorStory) ?
              (
            <React.Fragment>
            <Button className="btn" onClick={onTailorClick}>
              <span className="book-button-text">{t("copy_book_btn")}</span>
              <span>
                <IoIosCopy className="book-btn-icon" />
              </span>
            </Button>
              <Button className="btn" onClick={onPublishClick}>
              <span className="book-button-text">{t("publish_book_btn")}</span>
              <span>
                <IoIosPaperPlane className="book-btn-icon" />
              </span>
            </Button>
          </React.Fragment>
            ):(
          <Button className="btn" onClick={onPublishClick}>
              <span className="book-button-text">{t("publish_book_btn")}</span>
              <span>
                <IoIosPaperPlane className="book-btn-icon" />
              </span>
            </Button>
             )  )}
        </span>
           <>
          {showPDFLoadToast && (
          <PdfDownloadLoader
            header_text={t('export_book_pdf_loader_header')}
            body_text = {pdfUploadToastBody}
            download_link = {pdfUploadLink}
            position="top-center"
            show_done_btn="true"
            setShowToast={setShowPDFLoadToast}
            showToast={showPDFLoadToast}
            setPdfuploadToastBody={setPdfuploadToastBody}
            setPdfUploadLink={setPdfUploadLink}
            initialToastBody={initialToastBody}
        />
      )}
    </>
      </div>
      )
}