import React, { useState, useEffect } from "react";
import { get_user, get_group_name } from "../BookEdit/DBUtils";
import BooksCarousel from "../BookSearch/BooksCarousel";
import {performBookSearch} from '../Common/SearchAPI'
import { getCurrentUser } from "../Common/LocalStorage";
import { useTranslation } from "react-i18next";
import {get_org_stories} from '../BookEdit/DBUtils'
import { useParams, Link } from "react-router-dom";
import { Button, Container } from 'react-bootstrap';
import { MdAdd } from "react-icons/md";
import { BookPermissions } from "../BookEdit/Consts";
import './organization.css'

export const OrganizationStoriesPage = ({userMetaString, groupName,isDisabled='false'}) => {
  const [organization_books, set_organization_books] = useState();
  const [user, setCurrentUser] = useState(null);
  const [groupId, setGroupId] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  //const [groupName, setGroupName] = useState("")

  const { i18n,t } = useTranslation();
  const params = useParams();
  const getDirection = () => (i18n.language=== "he" ? "rtl" : "ltr");

  useEffect(() => {
    async function fetchData() {
      try{
        setIsLoading(true);
        let userObject = JSON.parse(userMetaString)
        let userData = userObject
        setCurrentUser(userObject)

        let groupId = null
        if (userData.organizations && userData.organizations.groups && userData.organizations.groups.length > 0)
        {
          groupId = userData.organizations.groups[0].group_id
          setGroupId(groupId)
          const locale = i18n.language.includes("he") ? "IL" : "";
          await get_org_stories(groupId, locale).then(search_response=>{
            set_organization_books(search_response)
          }).finally(() => {
        setIsLoading(false)
      })
      }
    } 
    catch (err) {
        console.error("failed to fetch user books", err);
      }
    }
    fetchData();
  }, [i18n.language]);

  return (
    <div>
      <Container dir={getDirection()}>
        <h4 className={i18n.language=='he'? "categoryHeaderHe" : "categoryHeader" }>{t("organization_books_header")+ " " +groupName}</h4>
        {
          isDisabled=='false' ?(
          <Link to={"/edit"+"/"+groupId} >
              <Button className="add_button mb-4">{t("new_story_caption")}<MdAdd/></Button>
          </Link>
          ):''
        }
        {isLoading ? (
            <div className='categoryHeader'>{t("loading_stories")}</div>
            ) :
        (organization_books && organization_books.length > 0 && user && user.email) ? (
          <BooksCarousel id="personal_user_books" books={organization_books} user={user.email} getDirection={getDirection} groupId={groupId} />
        ) : (
          <div>no-books</div>
        )}
      </Container>
    </div>
  );
};
