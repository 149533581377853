import React from 'react';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import {fetchNikudFromAPI} from './NikudUtils'
import { importNamespaceSpecifier } from '@babel/types';

export const HebrewNikudBtn = ({ editorState, onChange }) => {
const addNikud = async() => {
    const contentState = editorState.getCurrentContent();
    const entireSelection = SelectionState.createEmpty(contentState.getFirstBlock().getKey()).merge({
        anchorOffset: 0,
        focusOffset: contentState.getLastBlock().getText().length,
    });

    let textWithNikud = await fetchNikudFromAPI(contentState.getPlainText());
    console.log("text with nikud:" + textWithNikud);

    const cleanedTextWithNikud = textWithNikud.replace(/\s+/g, ' ').trim();
    console.log("Cleaned Text with Nikud:", cleanedTextWithNikud);

    const newContentState = Modifier.replaceText(contentState, entireSelection, cleanedTextWithNikud)
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
    onChange(newEditorState);
  };
  
  return (
    <div
      onClick={addNikud}
      className="rdw-option-wrapper"
      style={{
        cursor: 'pointer',
        padding: '10px 10px 14px 10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        color: 'rgb(33, 37, 41)',
        fontSize: '22px',
        textAlign: 'center',
        marginRight: '5px',
        marginTop:'3px',
      }}
      title="Add Nikud"
    >
      אָ
    </div>
  );
};
