import React, { useState } from "react";
import { Toast, ToastContainer, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { t } from 'i18next';

import "./toast.css";

export const PdfDownloadLoader = ({
  header_text,
  body_text,
  download_link,
  position,
  show_done_btn = false,
  setShowToast,
  showToast,
  setPdfuploadToastBody,
  setPdfUploadLink,
  initialToastBody
}) => {
  const { i18n } = useTranslation();
  //const [showToast, setShowToast] = useState(true);
  const isHebrew = i18n.resolvedLanguage === "he";
  const dir = isHebrew ? "rtl" : "ltr"

  const handleClose = () => {
    console.log("Toast should be closed");
    setPdfuploadToastBody( initialToastBody );
    setPdfUploadLink(null);
    setShowToast(false);
  };

  return (
    <div
      aria-live="polite"
      aria-atomic="true"
      className="bg-dark position-relative"
      dir={dir}
    >
      <ToastContainer className="p-3 toast-loading" position={position}>
        {showToast && (
          <Toast onClose={handleClose}>
            <Toast.Header
              closeButton
              style={{
                display: "end", // Use flexbox for alignment
                justifyContent: "space-between", // Space out header_text and the close button
                alignItems: "center", // Vertically align items
                width: "100%", // Ensure the header takes up the full width
              }}
            >
              <strong
                style={{
                  flexGrow: 1,
                  textAlign: dir,
                }}
              >
                {header_text}
              </strong>
            </Toast.Header>
            <Toast.Body>
              <span className="body_text">
                {!download_link ? body_text : ''}
                {download_link && <a
                  href={download_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    console.log("Link clicked, closing toast");
                    handleClose();
                  }}
                >
                  {t('export_book_pdf_download_done')}
                </a> }
              </span>
              {download_link ? 
                null
                :      
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin:"5px" }}>
                  <small>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </small>
                </div>
              }
            </Toast.Body>
            {show_done_btn && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "5px",
                }}
              >
                <Button onClick={handleClose}>Close</Button>
              </div>
            )}
          </Toast>
        )}
      </ToastContainer>
    </div>
  );
};
