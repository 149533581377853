import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./Common/locales/en.json";
import hi from "./Common/locales/hi.json";
import ar from "./Common/locales/ar.json";
import he from "./Common/locales/he.json";
import ge from "./Common/locales/ge.json";

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: { translations: en },
    hi: { translations: hi },
    ar: { translations: ar },
    he: { translations: he },
    ge: { translations: ge },
  },
  lng: new URLSearchParams(window.location.search).get("lng") || // Priorty to URL
  localStorage.getItem("i18nextLng") || // Then LocalStorage
  (navigator.language && navigator.language.split("-")[0].toLowerCase()) || // Then Browser's lang
  "en",
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
